<template>
  <div class="container">
    <div v-if="['fetch', 'queued', 'running'].includes(nowStatus)">
      <a-spin style="margin-right: 12px;" />
      <span v-if="nowStatus === 'queued'">In queue, waiting for running (Position: {{ fetchResult.position }})</span>
      <span v-if="nowStatus === 'running'">Running...</span>
    </div>

    <div v-if="nowError">{{ nowError.toString() }}</div>

    <div v-if="nowStatus === 'done'" style="width: 100%; text-align: center;">
      <img style="max-width: 95%;" :src="`/download/${taskId}/image.png`" alt="result" />
    </div>

  </div>

  <div v-if="nowStatus === 'done'" class="container" style="width: 100%; margin-bottom: 12px;">
    <a-button type="primary" @click="downloadAttach">
      Download Result
    </a-button>
  </div>

  <div v-if="nowStatus === 'done'" style="margin: 0 24px;">
    <!-- <p>Running </p> -->
    <a-table :columns="columns" :data-source="resultTable" :scroll="{ x: 'max-content' }" :pagination="false"
      size="small">
      <template #bodyCell="{ column, text }">
        <template v-if="column.dataIndex === 'sequences'">
          <a>{{ text }}</a>
        </template>
      </template>
      <template #title>Strength Sequences</template>
    </a-table>
  </div>

  <div style="margin: 0 24px; ">
    <a-collapse v-model:activeKey="activeKey" :bordered="false" ghost>
      <a-collapse-panel key="1" header="Log" class="header">
        <pre class="codeblock" style="margin-top: -12px;"><template v-for="logItem in logEntity"
          :key="logItem">{{ logItem }}<br /></template></pre>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import { DownloadOutlined } from '@ant-design/icons-vue';

const columns = [
  {
    title: 'Index',
    dataIndex: 'key',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Sequence',
    dataIndex: 'sequence',
  },
  {
    title: 'Strength',
    dataIndex: 'strength',
    sorter: (a, b) => a.strength - b.strength,
  },
];

export default {
  name: 'StepResult',
  setup() {
    return {
      columns,
      DownloadOutlined,
    }
  },
  computed: {
    resultTable() {
      return this.fetchResult.result || [];
    }
  },
  methods: {
    log(msg) {
      this.logEntity.push(`${new Date().toLocaleString()} - ${msg}`);
    },

    processDone() {
      this.log('Task completed')

      clearInterval(this.intervalId);
      this.nowStatus = 'done';
      this.$emit('updateEnablePrev', true);

      this.activeKey.pop();
    },
    downloadAttach() {
      // window.open(`/download/${this.taskId}/result.txt`);
      let a = document.createElement('a');
      a.href = `/download/${this.taskId}/result.txt`;
      a.download = `result_${this.taskId}.json`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    createTask() {
      this.nowStatus = 'queued';

      this.$emit('updateEnablePrev', false);

      this.log('Creating task...')

      return axios.post('/generate', this.paramData)
        .then(res => {
          this.fetchResult = res.data;
          this.taskId = res.data.task_id;
          console.log(this.taskId, res.data)

          this.log(`Task created, task ID: ${this.taskId}`)

          this.intervalId = setInterval(() => {
            axios.get(`/status/${this.taskId}`)
              .then(res => {
                this.fetchResult = res.data;

                switch (res.data.status) {
                  case 'completed':
                    this.processDone(res.data)
                    break;

                  case 'running':
                    this.nowStatus = 'running';

                    // this.log(`Task is running, progress: ${res.data.progress}`)
                    break;
                }

                if (res.data.message !== this.lastMessage && res.data.message) {
                  this.log(res.data.message);
                }
                this.lastMessage = res.data.message;
              })
              .catch(err => {
                this.log('Error occurred, error: ' + err.toString());

                this.nowError = err;
                console.error(err);
                this.nowStatus = 'error';
                this.$emit('updateEnablePrev', true);
              });
          }, 1000);
        })
        .catch(err => {
          this.log('Error occurred, error: ' + err.toString());

          this.nowError = err;
          console.error(err);
          this.nowStatus = 'error';
          this.$emit('updateEnablePrev', true);
        });
    }
  },
  props: {
    paramData: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    activeKey: ref(['1']),
    intervalId: null,
    taskId: '',
    nowStatus: 'queued', // queued, running, done, error
    fetchResult: {},
    nowError: null,
    logEntity: [],
    lastMessage: '',
  }),
  beforeMount() {
    console.log('indata', this.paramData)

    this.activeKey = ['1'];
    this.logEntity = [];

    this.log('Initialization successful, ready to create task...')

    this.createTask();
  },
  created() {
  },
}
</script>
<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 16px;
}

.codeblock {
  font-family: monospace, monospace;
  font-size: 14px;
  padding: 12px;
  /* dark theme */
  background-color: #282c34;
  color: #abb2bf;
  border-radius: 4px;
}

.header {
  span {
    font-size: 16px;
  }

  div {
    padding: 0;
  }
}
</style>
